<template>
  <div>
    <BaseDialog
      class="dialog-warpper"
      :dialogVisible="visible"
      :title="type == 'add' ? '新增风格' : '编辑风格'"
      width="22%"
      @closeHandle="handleClose"
    >
      <template>
        <el-form :model="form" :rules="rules" ref="form" label-width="100px">
          <el-form-item label="风格名称:" prop="styleName">
            <el-input clearable style="width: 240px" placeholder="请输入风格名称" v-model.trim="form.styleName"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <loadingBtn type="primary" @click="onConfirm"> 确定 </loadingBtn>
        <loadingBtn @click="onCancel"> 取消 </loadingBtn>
      </template>
    </BaseDialog>
  </div>
</template>

<script>
import { deepClone } from '@/components/avue/utils/util'
import { createStyle, editStyle } from '@/api/finishedProductManageApi'

export default {
  props: {
    type: {
      type: String,
      default: 'add'
    },
    form: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      default: false
    },
    sup_this: {
      type: Object,
      default: () => ({})
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.formCopy = deepClone(this.form)
      }
    }
  },
  data() {
    return {
      formCopy: '',
      rules: {
        styleName: [{ required: true, message: '请输入风格名称', trigger: 'blur' }]
      }
    }
  },

  methods: {
    handleClose() {
      this.$emit('update:form ', {})
      this.form.styleName = ''
      this.$emit('update:visible', false)
    },
    onCancel() {
      this.$emit('update:form ', this.formCopy)
      this.$message.warning('您取消了操作')
      this.$emit('update:visible', false)
    },
    async onConfirm() {
      try {
        await this.$refs.form.validate()
      } catch (error) {
        return false
      }
      const data = {
        description: this.form.styleName
      }
      if (this.type == 'edit') Object.assign(data, { id: this.data.id })
      const message = this.type == 'add' ? '成功新增风格' : '成功修改风格'
      const fun = this.type == 'add' ? createStyle : editStyle
      try {
        const { code } = await fun(data)
        if (code != 0) return
        this.$emit('update:visible', false)
        this.$message.success(`${message}`)
        this.sup_this.init()
      } catch (error) {}
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-warpper {
}
</style>
